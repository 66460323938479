
import { useTable, CellProps, Column } from "react-table";

import { fetchSilabusV2 } from "../http/teacherApi";
import { useMemo, useState } from "react";

export async function loaderSilabusStudentV2({ params }: any) {

    const { classID, subjectID, teachingBegin, researchingEnd } = { classID: 2, subjectID: 7, teachingBegin: '2024-09-16', researchingEnd: '2025-10-31' };

    const modules = await fetchSilabusV2(classID, subjectID, teachingBegin, researchingEnd);
    console.log(modules);
    const themes = modules[3]['themes'];



    const data = [/* { id: 1, theme: 'Тема темная', desc: 'Задание заданное', rowSpan: 1, skills: <ul></ul>, style: {} } */];

    for (const theme of themes) {
        var skills = <></>;
        if (theme?.skills.length) {
            skills = <ul>{theme.skills.map((skill: any) => { return (<li>{(skill.name) ? skill.name : "!"}</li>) })}</ul>
        }
        if (theme?.descriptions.length) {
            var rowSpan = theme.descriptions.length
            for (const desc of theme.descriptions) {
                data.push({ theme: theme['name'] as string, skills, desc: desc.description, id: data.length + 1, rowSpan, style: { background: desc?.params?.color } })
                rowSpan = 0;
            }
        } else {
            data.push({ theme: theme['name'] as string, skills, desc: '', id: data.length + 1, rowSpan: 1 })
        }
    }


    return { data, classID };
}


const styleModules = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleHeader = { background: '#bfbfbf', padding: '3px', border: 'solid 1px gray', fontSize: '12px' };
const styleData = { padding: '3px', border: 'solid 1px gray' };
const styleCellHover = { border: '1px solid gray',  /* textAlign: 'center', */ cursor: 'pointer', background: "#ffd110", }


//export const SilabusModuleStudentV2Table = (module/* , data */: any, hovered: any, onClick: (item:any) => void) => {
    export const SilabusModuleStudentV2Table = ({ module, hovered, onClick }: any) => {
    const [editRowId, setEditRowId] = hovered;


    //console.log("111", module);

    // if (!module) return <></>

    const handleEditClick = (rowId: any) => {
        //  console.log("edit click", rowId);

        setEditRowId(rowId.row);
        //setEditColumnId(rowId.column);
    };

    const handleOnClick = (item:any) => {
        onClick?.(item)
    }

    const data = module['themes'];
//    const meta = { class: '1 Класс', student: 'Студент Студентович Студентов', module: 'Модуль 1' }

    const columns: Column[] = useMemo(() => [
        {
            Header: `${module['name']}`,
            accessor: 'module',
            columns: [{
                Header: 'Тема',
                accessor: 'theme',
                Cell: ({ value, row }: any) => {
                    if (row.original.rowSpan === 0) return null;
                    return (<td rowSpan={row.original.rowSpan} style={styleModules}>{value}</td>)
                }
            }, {
                Header: 'Умения',
                accessor: 'skills',
                Cell: ({ value, row }: any) => {
                    if (row.original.rowSpan === 0) return null;
                    return (<td rowSpan={row.original.rowSpan} style={styleHeader}>{value}</td>)
                }


                //                <td style={styleHeader}>{value}</td>
            }, {
                Header: 'План выполнения',
                accessor: 'plan',
                Cell: ({ value, row }: any) => <td {...(row.original.id === editRowId) ? { style: styleCellHover } : { style: { ...row.original.style } }} /* onMouseEnter={() => handleEditClick({ row: row.original.id })} */ onMouseLeave={() => handleEditClick({ row: 0 })} onClick={() => handleOnClick(row.original.id)} onMouseMove={() => handleEditClick({ row: row.original.id })}>{value}</td>
            }]
        }
        ,
        {
            Header: 'hide',
            accessor: 'hide',
            columns: [
                {
                    Header: 'Задания',
                    accessor: 'desc',
                    Cell: ({ value, row }: any) => <td {...(row.original.id === editRowId) ? { style: styleCellHover } : { style: { ...row.original.style } }} /* onMouseEnter={() => handleEditClick({ row: row.original.id })} */ onMouseLeave={() => handleEditClick({ row: 0 })} onClick={() => handleOnClick(row.original.id)} onMouseMove={() => handleEditClick({ row: row.original.id })}>{value}</td>
                }, {
                    Header: 'Расположение задания',
                    accessor: 'location',
                    Cell: ({ value, row }: any) => <td  {...(row.original.id === editRowId) ? { style: styleCellHover } : { style: { ...row.original.style } }} /* onMouseEnter={() => handleEditClick({ row: row.original.id })} */ onMouseLeave={() => handleEditClick({  row: 0 })} onClick={() => handleOnClick(row.original.id)} onMouseMove={() => handleEditClick({ row: row.original.id })}>{value}</td>
                }, {
                    Header: 'Баллы Макс/Факт',
                    accessor: 'score',
                    Cell: ({ value, row }: any) => <td  {...(row.original.id === editRowId) ? { style: { ...styleCellHover, textAlign: 'center' } } : { style: { ...row.original.style, textAlign: 'center' } }} /* onMouseEnter={() => handleEditClick({ row: row.original.id })}  */onMouseLeave={() => handleEditClick({  row: 0 })} onClick={() => handleOnClick(row.original.id)} onMouseMove={() => handleEditClick({ row: row.original.id })}>{value}</td>
                }, {
                    Header: 'Самооценка',
                    accessor: 'self_score',
                    Cell: ({ value, row }: any) => <td  {...(row.original.id === editRowId) ? { style: { ...styleCellHover, textAlign: 'center' } } : { style: { ...row.original.style, textAlign: 'center' } }} /* onMouseEnter={() => handleEditClick({ row: row.original.id })} */ onMouseLeave={() => handleEditClick({  row: 0 })} onClick={() => handleOnClick(row.original.id)} onMouseMove={() => handleEditClick({ row: row.original.id })}>{value}</td>
                }
            ]
        },
    ], [editRowId]);

    //console.log(data, columns);

    const tableInstance = useTable({ columns, data, })

    const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = tableInstance

    interface StringArray {
        [index: string]: {};
    }

    const colspan: StringArray = { module_0: 7, class_7: 3 }
    const style: StringArray = { skills: styleHeader, plan: styleHeader, desc: styleHeader, location: styleHeader, score: styleHeader, self_score: styleHeader }

    return (
        <table {...getTableProps()} style={{ width: '100%', marginBottom: '10px' }}>
            <thead>
                {headerGroups.map((headerGroup: any) => (
                    <tr key={Math.random()} {...headerGroup.getHeaderGroupProps()} style={styleModules}>
                        {headerGroup.headers.map((column: any) => {
                            if (!(column.id === 'hide_3')) return (
                                <th
                                    {...column.getHeaderProps([{
                                        style: style[column.id],
                                        colspan: colspan[column.id]
                                    }])}
                                >
                                    {column.render('Header')}
                                </th>
                            )
                        })}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row: any) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()} >
                            {row.cells.map((cell: any) => cell.render('Cell'))}
                        </tr>
                    )
                })
                }
            </tbody>
            {/* Секция футера */}
            {/* <tfoot>
                        {footerGroups.map(footerGroup => (
                            <tr {...footerGroup.getFooterGroupProps()}>
                                {footerGroup.headers.map(column => {
                                    console.log("footer", column);
                                    
                                    return (
                                        <td
                                            {...column.getFooterProps()}
                                            style={{
                                                padding: '8px',
                                                background: '#f0f0f0',
                                                border: '1px solid #ddd',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {column.render('Footer')
                                            }
                                        </td>
                                    )
                                })}
                            </tr>
                        ))}
                    </tfoot> */}

        </table>
    )

}

export default SilabusModuleStudentV2Table;
