import React, { useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Context, store } from '..';
import { Container, Row, Col } from 'react-bootstrap';
import StudentBar from '../components/student/StudentBar';
import StudentsShedule from '../components/student/StudentsShedule';
import { fetchSchedule, fetchScheduleGrid } from '../http/adminApi';
import { fetchModules } from '../http/teacherApi';
import { getClassByUserID, getSubjectsByClassID } from '../http/studentApi';
import StudentStars from '../components/student/star/StudentStar';
import StudentSilabusNM from '../components/student/syllabus/StudentSilabusNM';
import StudentSillabus from '../components/student/syllabus/StudentSillabus';
import StudentResponses from '../components/student/syllabus/StudentResponses';
import StudentSubBar from '../components/student/StudentSubBar';
import { getStudentsByParent } from '../http/parentApi';
import StudentsSelector from '../components/parent/StudentsSelector';
import ParentBar from '../components/parent/ParentBar';
import SchcollBook from '../components/utilites/SchcollBook';
import ParentSubBar from '../components/parent/ParentSubBar';
import StudentAttendanceStat from '../components/student/statistic/StudentAttendanceStat';
import StudentStarL2 from '../components/student/star/StudentStarL2';
import StudentStarL3 from '../components/student/star/StudentStarL3';
import StudentStudentStat from '../components/student/statistic/StudentStudentStat';
import { logout } from '../http/userApi';
import { Navigate, Outlet, redirect, useLoaderData, useNavigate } from 'react-router-dom';
import { START_ROUTE } from '../utils/consts';
import EventsCalendar from '../components/utilites/EventsCalendar';
import ParentMeetingVisit from '../components/parent/ParentMeetingVisit';
import ParentMeeting from '../components/parent/ParentMeeting';
import NavBar from '../components/NavBar';
import { message } from 'antd';
import MenuBar from '../components/MenuBar';
import { loaderStudentPerformance, StudentPerformance } from '../components/student/statistic/StudentPerformance';
import ParentStars, { parentStarsLoader } from '../components/student/star/ParentStar';
import { loaderParentPerformance, ParentPerformance } from '../components/student/statistic/ParentPerformance';
import SilabusStudentV2, { loaderSilabusStudentV2, silabusStudentV2Router } from '../components/SilabusStudentV2';
//import { user } from '..';

export const loaderParent = async ({ request, params }: any) => {
  //console.log('parent loader', request, params);
  const { studentID } = params;

  const students = await getStudentsByParent()
  //console.log(students);

  if (!studentID) {
    //return redirect(`${students[0].id}/stars`)
  }

  return { students, studentID }
}


const Parent = observer(() => {

  const navigate = useNavigate();
  const { studentID } = useLoaderData() as any;

  const { store } = useContext(Context)

  const [students, setStudents] = useState(null);
  const [newStudentID, setNewStudentID] = useState(0);
  //const { user } = useContext(Context)
  //if (user?.role != 'parent') return (<>Доступ запрещен!</>);

  const { parent } = useContext(Context)
  const { admin } = useContext(Context)

  useEffect(() => {
    loadData()
    store.setRole('parent');
  }, [])

  const loadData = async () => {
    const students = await getStudentsByParent()
    //console.log("parent.tsx students", students);

    setStudents(students);

    if (students?.length > 0) {
      parent.setStudents(students)
      parent.setStudentData(students[0])

      const classID = await getClassByUserID(students[0].id)
      parent.setStudentClass(classID)
      //console.log("ClassID: ", classID);


      await fetchSchedule(classID).then(data => parent.setStudentSchedule(data))
      await getSubjectsByClassID(classID).then(data => parent.setStudentSubjects(data))
      await fetchModules(classID, parent.studentSubjects[0].id).then(data => parent.setStudentModules(data))
      await fetchScheduleGrid(classID).then(data => admin.setLessonsInterval(data))
    } else {
      // нет студентов у родителя, выходим
      //alert("Не заданы студенты, обратитесь к администратору!")
      message.error("Не заданы студенты, обратитесь к администратору!");
      store.logout();
      //redirect('/login', { replace: true })
      return null;
    }
  }

  useEffect(() => {
    loadStudentData()
    //setNewStudentID(parent?.studentData?.id)
    if (parent?.studentData?.id) navigate(`../parent/${parent?.studentData?.id}/stars`)

  }, [parent?.studentData])

  //if (newStudentID != studentID ) return <Redirect to='{newStudentID'/>

  const loadStudentData = async () => {
    //console.log("SD2", parent?.studentData, parent?.students);
    if (parent?.studentData?.id) {
      parent.setSelectedSubMenu({})
      const classID = await getClassByUserID(parent?.studentData?.id)
      parent.setStudentClass(classID)

      //await fetchSchedule(classID).then(data => parent.setStudentSchedule(data))
      await getSubjectsByClassID(classID).then(data => parent.setStudentSubjects(data))
      await fetchModules(classID, parent.studentSubjects[0].id).then(data => parent.setStudentModules(data))
      //await fetchScheduleGrid(classID).then(data => admin.setLessonsInterval(data))
    }
  }

  const params = { classID: parent.studentClass, subject: parent.selectedSubject };

  if (students) return (
    <Row className='mt-2'>
      <Col md={2}>
        <MenuBar menu={sideMenu} />
      </Col>
      <Col md={9}>
        {/* <ParentSubBar /> */}
        <Outlet />
      </Col>
    </Row>
  );

  if (students && false) return (
    <>


      <Row className='mt-2'>
        <Col md={3}>
          {/* <MenuBar menu={sideMenu} /> */}
          <ParentBar />
          {parent.selectedSideMenu?.id == 6 &&
            <SchcollBook {...params} />
          }
        </Col>
        <Col md={9}>
          <ParentSubBar />
          {/*  <StudentsSelector /> */}
          {parent.selectedSideMenu?.id == 6 &&
            <SchcollBook {...params} />
          }
          {Boolean(!parent.selectedSubMenu?.id && parent.selectedSideMenu?.id == 1) && <StudentsShedule /* schedule={parent.studentSchedule} */ {...{ mode: 'parent' }} />}
          {/*    {Boolean(!student.selectedSubMenu?.id & student.selectedSideMenu?.id == '2') && <StudentSilabus />} */}
          {Boolean(!parent.selectedSubMenu?.id && parent.selectedSideMenu?.id == 4) && <StudentStars {...{ mode: 'parent' }} />}
          {parent.selectedSubMenu?.id == 41 && <StudentStarL2 {...{ mode: 'parent' }} />}
          {parent.selectedSubMenu?.id == 42 && <StudentStarL3 {...{ mode: 'parent' }} />}

          {Boolean(!parent.selectedSubMenu?.id && parent.selectedSideMenu?.id == 6) && <StudentSilabusNM {...{ student: parent }} />}

          {parent.selectedSubMenu?.id == 61 && <StudentSillabus {...{ student: parent }} />}
          {parent.selectedSubMenu?.id == 62 && <StudentResponses {...{ student: parent }}/* student={parent} */ />}

          {Boolean(!parent.selectedSubMenu?.id && parent.selectedSideMenu?.id == 7) && <StudentAttendanceStat {...{ studentID: parent.studentData?.id }} />}
          {Boolean(!parent.selectedSubMenu?.id && parent.selectedSideMenu?.id == 8) && <StudentStudentStat /* mode={'parent'} */ {...{ studentID: parent.studentData?.id }} />}

          {Boolean(!parent.selectedSubMenu?.id && parent.selectedSideMenu?.id == 9) && <EventsCalendar />}

          {Boolean(!parent.selectedSubMenu?.id && parent.selectedSideMenu?.id == 10) && <ParentMeeting />}
          <Outlet />
        </Col>
      </Row>
    </>
  );

  return (
    <>Нет студентов!</>
  )
});

export const parentRouter = [
  //{ index: true, element: < Navigate to='eventcalendar' /> },
  { index: true, element: <></> },
  { path: 'eventcalendar', element: <EventsCalendar /> },
  { path: 'meeting', element: <ParentMeeting /> },
  { path: 'attendancestat', element: <StudentAttendanceStat /> },
  //  { path: 'subjectstar/', element: <TutorSubjectStar />, loader: TutorSubjectStarLoader },
  { path: 'stat', element: <ParentPerformance />, loader: loaderParentPerformance }, // <StudentStudentStat />
  //{ path: 'routes', element: <StudentSilabusNM /> },
  { path: 'shedule', element: <StudentsShedule {...{ mode: 'parent' }} /> },
  //{ path: 'stars', element: <ParentStars />, loader: loaderParent },
  { path: 'stars', element: <ParentStars />, loader: parentStarsLoader },
  { path: 'sillabus/*', element: <SilabusStudentV2 />, loader: loaderSilabusStudentV2, children: silabusStudentV2Router },

];

const sideMenu = [
  { id: 4, name: "Прогресс", link: 'stars' },
  { id: 1, name: "Расписание", link: 'shedule' },
  { id: 7, name: "Посещаемость", link: 'attendancestat' },
  { id: 8, name: "Успеваемость", link: 'stat' },
//  { id: 6, name: "Маршруты", link: 'routes' },
  { id: 6, name: "Маршруты", link: 'sillabus' },
  { id: 10, name: "Запись на встречу", link: 'meeting' },
  { id: 9, name: "Календарь событий", link: 'eventcalendar' },
  // { id: 3, name: "Настройки" },

]

export default Parent;