import React, { Suspense, useDeferredValue, useEffect, useRef, useState } from 'react';
//import { Suspense} from 'react';
import { Await, defer, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';


import styled from 'styled-components';
import studentAPI from '../http/classStudentAPI';
import silabusV2API from '../http/classSilabusV2API';
import { Card } from 'antd';
import HTMLViewer from './utilites/HTMLViewer';
import { Button } from 'react-bootstrap';
import FilesUpload from './utilites/FilesUpload';
import Editor from './utilites/quill/Editor';
import ReactQuill from 'react-quill';
import MyInput from './MyInput';
import { Input } from 'antd/lib';


export async function loaderModalAnswerV2({ params }: any) {

  var modal_data: any = { loading: true };

  if (params['id']) {
    modal_data = await silabusV2API.getDescription(params['id']);
  }

  console.log("loader MA", modal_data);
  return defer({ modal_data });
}


export async function actionModalAnswerV2({ request, params }: any) {
  console.log("action MA", request, params);
  return {};

/*     let formData = await request.formData();
  console.log('=====dire stat act===============================');
  console.log(formData);
  console.log('====================================');
  return await updateSilabus(params.id, {
      add: formData.get('add') === 'true',
  });
*/}


// Типы для пропсов компонента
interface ComponentProps {
  //isOpen: boolean;
  //onClose: () => void;
  data: { text: any, fileList: [], id: number }/*  | null */;
}


/* const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${(props: { isOpen: boolean }) => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 12px;
  border-radius: 8px;
  width: auto; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`; */

const AnswerEdit: React.FC<ComponentProps> = ({ /*isOpen, onClose,*/ data }) => {

  console.log("-> ", data);

  const [quill, setQuill] = useState(data['text']);
  const submit = useSubmit();

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", /* "video" */],
      ["clean"],
    ],
  };


  //const [value, setValue] = useState(modal_data['data']);
  // Устанавливаем фокус при загрузке компонента
  useEffect(() => {
    if (quillRef.current) {
      const quillInstance = quillRef.current.getEditor();
      quillInstance.focus();
    }
  }, []); // Пустой массив зависимостей означает, что эффект выполнится только один раз после монтирования

  const quillRef = useRef<ReactQuill>(null);

  const onClick = (item: any) => {
    console.log("click", item);
    
    /* const [check, id] = item.split("-");
    if (check === 'd') {
      let formData = new FormData();
      formData.append("id", id);
      //formData.append("classname", e?.label);
      //        submit(formData, { method: "GET", action: `${id}` });
      submit(null, { method: "GET", action: `${id}` });
    } */
    return null;
  }


  return (
    <Card /* title={"Ответ"}  */ style={{ 'marginBottom': '8px', 'backgroundColor': 'lightgray' }}>
      <ReactQuill ref={quillRef} modules={modules} theme="snow" value={quill} onChange={(val) => setQuill(val)} style={{ 'marginBottom': '8px', 'backgroundColor': 'white' }} />
      {/*           <Editor
            data={modal_data['answer']}
            //funcToSave={(val:any) => changeAnswer(val, resp.id)}
            placeholder='Введите ответ на домашнее задание'
          ></Editor> */}
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px', justifyContent: 'space-between' }}>
        <FilesUpload style={{ flex: 2 }}
          fileList={data['fileList']}
          action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'responsesV2'}&id=${data['id']}`}
        > </FilesUpload>
        <Input name='111' placeholder='Оценка' style={{ flex: 1, maxWidth: '100px' }} />
      </div>
      <Button onClick={() => onClick({quill})} style={{ float: 'right' }}>Сохранить</Button>
    </Card>
  );
};



export default AnswerEdit;