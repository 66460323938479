import ClassAPI from "./classAPI";

class ClassSilabusV2API extends ClassAPI {
    constructor () {
        super('silabusV2')
    }

    async getDescription (id: string) {
        const ret = await this.get({url: `description/${id}`})
        //return (ret) ? {...ret} : {};
        return ret
    }
}

const  silabusV2API  = new ClassSilabusV2API()

export default silabusV2API;

