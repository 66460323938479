import React, { Suspense, useDeferredValue, useEffect, useRef, useState } from 'react';
//import { Suspense} from 'react';
import { Await, defer, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';


import styled from 'styled-components';
import studentAPI from '../http/classStudentAPI';
import silabusV2API from '../http/classSilabusV2API';
import { Card, Select } from 'antd';
import HTMLViewer from './utilites/HTMLViewer';
import { Button } from 'react-bootstrap';
import AnswerView from './AnswerView';
import AnswerEdit from './AnswerEdit';


export async function loaderModalAnswerV2({ params }: any) {

  const tactID = Number(params['tactID'] || 0);
  const subjectID = Number(params['subjectID'] || 0);

  var modal_data: any = { loading: true };

  if (params['id']) {
    modal_data = await studentAPI.getDescription(params['id']);// 
    //silabusV2API.getDescription(params['id']);
  }

  console.log("loader MA", modal_data);
  return defer({ modal_data, tactID, subjectID });
}


export async function actionModalAnswerV2({ request, params }: any) {
  console.log("action MA", request, params);
  return {};

/*     let formData = await request.formData();
  console.log('=====dire stat act===============================');
  console.log(formData);
  console.log('====================================');
  return await updateSilabus(params.id, {
      add: formData.get('add') === 'true',
  });
*/}


// Типы для пропсов компонента
interface ModalComponentProps {
  //isOpen: boolean;
  //onClose: () => void;
  //data: {} | null;
}


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${(props: { isOpen: boolean }) => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 12px;
  border-radius: 8px;
  width: auto; /*  300px; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Score = styled.div`
  background: loghtgreen;
  padding: 8px;
  border-radius: 8px;
  width: auto; /*  300px; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: right;
  font-weight: bold;
`;

const ModalAnswers: React.FC<ModalComponentProps> = (/* { isOpen, onClose, data } */) => {

  const { modal_data, tactID, subjectID } = useLoaderData() as any;
  const deferredQuery = useDeferredValue(modal_data);
  console.log("-> ", modal_data, deferredQuery);

  const navigate = useNavigate();


  


  const { isOpen, /* onClose,  data*/ } = { isOpen: true, /* onClose:()=>null,  data: {} */ }

  useEffect(() => {
    document.body.style.overflow = 'hidden'; // блокирует прокрутку окна
  }, [])
  /*   if (isOpen) {
      document.body.style.overflow = 'hidden'; // блокирует прокрутку окна
    } else {
      document.body.style.overflow = 'auto';
      return null;
    } */

  const onClose = () => {
    document.body.style.overflow = 'auto';
    //onClose?.()
    navigate(`../${tactID}/${subjectID}/`);
//    navigate(`..`);

  }

  console.log("modl");

  

  return (
    <ModalOverlay isOpen={isOpen} onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()} >
        
        <div style={{ maxHeight: '60vh', overflowY: 'scroll', 'marginBottom': '8px' }}>
          <Card title={"Задание"} style={{ 'marginBottom': '8px', 'backgroundColor': 'lightgray' }}>
            <HTMLViewer value={modal_data['description']['text']}></HTMLViewer>
          </Card>
        </div>
        {modal_data['responses'].length > 0 &&
          <>
            {modal_data['responses'][0]['teacherScore'] &&
              <Score /* title={"Ответ"}  */ style={{ 'marginBottom': '8px', 'backgroundColor': 'lightgreen' }}>
                Оценка: {modal_data['responses'][0]['teacherScore']}
                {modal_data['responses'][0]['studentScore'] &&
                  <>/ Самооценка : {modal_data['responses'][0]['studentScore']}</>
                }
              </Score>
            }

            {/* <AnswerView data={{ id: 1, fileList: [], text: 'Ответ студента на задание' }}></AnswerView>
        <AnswerView data={{ id: 1, fileList: [], text: 'Ответ учителя на задание' }}></AnswerView> */}
            {!modal_data['responses'][0]['teacherScore'] &&
              <AnswerEdit data={{ id: 1, fileList: [], text: '' }} />
            }
          </>
        }
        <Button onClick={onClose}>Закрыть</Button>
      </ModalContent>
    </ModalOverlay>
  );
};



export default ModalAnswers;