import { Card, Select } from "antd";
import EditStar from "../../utilites/star/EditStar";

const ParentSkillStars = (params) => {
    //console.log("skill tacts params", params);
    
    return (
        <Card style={{ maxWidth: '1000px', padding: '8' }}  title="Индивидуальный план развития" key={"b" + params.studentID}>
                         <div style={{ width: '100%', }}>
                <Select
                    mode='multiple'
                    placeholder='Выберите такт'
                    options={params.skillTacts}
                    style={{ width: '100%' }}
                    onChange={params.handleChangeSkillTacts}
                />
            </div>

            {params.starS.length > 0 &&
                <>
                    {params.starS.map((item, index) => {
                        //console.log("item", item);
                        
                        return (
                            <EditStar
                                key={"editstar" + index}
                                header={item.title}
                                starS={item.star}
                                setStarS={params.setStarS}
                                tipsTextData={params.tipsTextData}
                                starLayer={0}
                                saveData={params.saveData}
                                greenDesc='- оценка наставника'
                                yellowDesc='- самооценка студента'
                                //editable={item.editable}// ? true : params.editable}
                                editable={false}
                            />
                        )
                    }
                    )}
                </>
            }
        </Card>
    )
}

export default ParentSkillStars;