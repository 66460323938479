import { Form, NavLink, Outlet, redirect, useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import SchoolBook from "./SchoolBook";
import ModulesList from "../ModulesList";
import { fetchDescriptions, fetchModules, fetchRoutes, fetchSilabus, fetchTeacherClassesSubjects, fetchThemes, fetchTree } from "../../../http/teacherApi";
import dayjs, { Dayjs } from 'dayjs'
import { Button, Card, Col, GetProps, Row, Tree } from "antd";
import { Key, ReactNode, useEffect, useMemo, useState } from "react";
import { BiDotsHorizontal, BiPlus } from "react-icons/bi";
import ModuleCard, { ModuleType } from "../../ModuleCard";
import Select from 'react-select'
import RouteCard from "../../RouteCard";
import DescriptionCard from "../../DescriptionCardList";
import ThemeCard from "../../ThemeCard";

import { Typography, TreeProps, TreeDataNode } from 'antd';
const { Paragraph } = Typography;


const { DirectoryTree } = Tree;

export async function modulesAction({ params, request }: any) {
  const formData = await request.formData();
  console.log("silabusAction", formData);

  const updates = Object.fromEntries(formData);
  return redirect(`${updates.classsubject}`);

  /*   console.log("modules actions: ", params, classsubject);
    return redirect(classsubject) */
}

export async function modulesLoader({ params }: any) {
  const { selectedClassSubject, moduleID, themeID, routeID, descriptionID } = params;

  const raw_key: string[] = []
  raw_key.push(moduleID);
  if (themeID) raw_key.push(themeID);
  if (routeID) raw_key.push(routeID);
  if (descriptionID) raw_key.push(descriptionID);

  const key = raw_key.join('-');
  //console.log("modulesLoader", selectedClassSubject);

  /*   if (params?.params) {
      const args = params.params.split('-')
      console.log(args);
  
  
      switch (params['type']) {
        case 'module': await createModule(args[0], args[1]); return redirect('.'); break;
        case 'theme': await createTheme(args[0]); return redirect('.'); break;
        case 'route': await createRoute(args[1]); return redirect('.'); break;
        case 'description': await createDescription(args[2]); return redirect('.'); break;
      }
    }
   */
  if (selectedClassSubject) {
    //const moduleID = params.moduleID;
    const rawClassSubjects = await fetchTeacherClassesSubjects();
    const target = rawClassSubjects.find((item: any) => item.id === selectedClassSubject)

    const [classID, subjectID] = selectedClassSubject.split('|');
    const modules = await fetchModules(target.classID, target.subjectID);
    const tree = await fetchTree(target.classID, target.subjectID);
    //console.log("tree", tree);

    return { classID, subjectID, modules, target, tree, key };
  } else {
    const rawClassesSubjects = await fetchTeacherClassesSubjects();
    const classesSubjects = rawClassesSubjects.map((classub: any) => { return ({ value: classub.id, label: classub.className + ' / ' + classub.subjectName }) })
    //console.log("silabusLoader", params);

    return { classesSubjects, key };
  }
}


const AddButton = ({ type, key, name, onClick }: any) => {
  return {
    //title: <NavLink to={'new/' + type + '/' + key}>{name}</NavLink>,
    title: <NavLink to={key + '/' + 'new'}>{name}</NavLink>,
    key: `${key}-0`,
    selectable: false,
    isLeaf: true,
  }
  /* return {
    title: <Button size='small' onClick={onClick} >
      <BiPlus className="d-flex justify-content-between" />
      {name}
    </Button>,
    key: `${key}-0`,
    selectable: false,
    isLeaf: true,
  } */
}



interface LoaderType {
  target: { subjectName: string, className: string, id: string };
  classID: number;
  subjectID: number;
  classesSubjects: [];
  modules: ModuleType[];
  tree: DataNode[];
  key: string;
}

interface DataNode {
  title: string | ReactNode;
  key: string | number;
  isLeaf?: boolean;
  children?: DataNode[];
  selectable?: boolean;
}

type DirectoryTreeProps = GetProps<typeof Tree.DirectoryTree>;

const updateTreeData = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });


export const EmptySelected = () => {
  return (
    <Card >
      Выберете модуль в меню слева
    </Card>
  )

}

const initTreeData: DataNode[] = [
  /*   {
      title: 'Модуль 1:', key: '10', children: [
        { title: 'leaf 0-0', key: '0-0-0', isLeaf: true },
        { title: 'leaf 0-1', key: '0-0-1', isLeaf: true },
      ],
    },
    { title: 'Expand to load', key: '11' },
    { title: 'Tree Node', key: '20', isLeaf: true }, */
];

const key2number = (key: React.Key) => {
  //console.log('key2number', key);

  var params: string | number | undefined | string[] = undefined;
  var _type = 'module'
  var selected: Key[] = []
  var expanded: Key[] = []

  if (typeof (key) === 'number') {
    return { _type, id: key, selected, expanded }
  }
  if (typeof (key) === 'string') {
    params = key.split('-');

    switch (params.length) {
      case 1: _type = 'module'; expanded = [`${params[0]}`]; break;
      case 2: _type = 'theme'; expanded = [`${params[0]}`, `${params[0]}-${params[1]}`]; break;
      case 3: _type = 'route'; expanded = [`${params[0]}`, `${params[0]}-${params[1]}`, `${params[0]}-${params[1]}/* -${params[2]} */`]; break;
      case 4: _type = 'description'; expanded = [`${params[0]}`, `${params[0]}-${params[1]}`, `${params[0]}-${params[1]}-${params[2]}`, `${params[0]}-${params[1]}-${params[2]}/* -${params[3]} */`]; break;
      default: console.log('default');
    }
    //    const _expanded = selected.pop();
    //    console.log("expa", _expanded, selected);

    //    expanded = (_expanded) ? expanded : [];
    selected = [key];
    return { _type, id: params[params.length - 1], selected, expanded }
  }
  return {}
}


export default function Modules() {
  const { classID, subjectID, modules, classesSubjects, target, tree, key } = useLoaderData() as LoaderType;
  const navigate = useNavigate();

  const { id, _type, selected, expanded } = key2number(key);

  const [selectedView, setSelectedView] = useState<ReactNode>(EmptySelected());
  const [treeData, setTreeData] = useState<DataNode[]>(initTreeData/* useMemo(() => tree, [modules]) */);
  const [expandedKeys, setExpandedKeys] = useState<Key[] | undefined>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[] | undefined>([]);
  const submit = useSubmit();


  const change = (props: any) => {
    console.log(props);

  }

  const _add = (props: any) => {
    return (
      <Paragraph
        editable={{
          tooltip: 'Редактировать',
          onChange: (value) => change({ props, value }),
          triggerType: ['icon'/* , 'text' */],
        }}
      >
        {props?.title}
      </Paragraph>
    )
  }
  {/* <button className="float-end btn btn-sm" onClick={() => { alert(props?.key) }}>
          <BiDotsHorizontal />
        </button> */}

  const data = useMemo(() => ({
    get tree() {
      var modules: DataNode[] = [];
      /*       const tree: DataNode[] = [];
            if (modules) {
              for (const module of modules) {
                tree.push({
                  title: (module.name) ? `${module.name} (${dayjs(module.begin).format('DD MMM')} - ${dayjs(module.end).format('DD MMM')})` : "!",
                  key: module.id,
                  //selectable: false
                })
              }
              tree.push(AddButton({ type: 'module', key: `${classID}|${subjectID}`, name: 'Добавить модуль',  }))
            } */
      if (tree) {
        for (const module of tree) {
          var themes: DataNode[] = [];
          if (module.children) {
            for (const theme of module.children) {
              var routes: DataNode[] = [];
              if (theme.children) {
                for (const route of theme.children) {
                  var descriptions: DataNode[] = [];
                  if (route.children) {
                    for (const description of route.children) {
                      descriptions.push({ ...description, /* title: _add(route) */ })
                    }
                    descriptions.push({ title: 'Добавить задание', key: route.key+'-new', isLeaf: true })
                  }
                  routes.push({ ...route, /* title: _add(route) */ children: descriptions });
                }
                routes.push({ title: 'Добавить умение', key: theme.key+'-new', isLeaf: true })
              }
              themes.push({ ...theme, /* title: _add(theme) */ children: routes })
            }
            themes.push({ title: 'Добавить тему', key: module.key+'-new', isLeaf: true })
          }
          modules.push({
            ...module,
            /* title: _add(module), */
            children: themes,
          })
        }
      }
      modules.push({ title: 'Добавить модуль', key: 'new', isLeaf: true })
      return modules;
    }

  }), [tree])

  useEffect(() => {
    //console.log('useeffect', selected, expanded);

    setTreeData(data.tree)
    setSelectedKeys(selected);
    setExpandedKeys(expanded);

    /*     switch (_type) {
          case 'module': setSelectedView(<ModuleCard key={id} id={id}></ModuleCard>); break;
          case 'theme': setSelectedView(<ThemeCard key={id} id={id}></ThemeCard>); break;
          case 'route': setSelectedView(<RouteCard key={id} id={id}></RouteCard>); break;
          case 'description': setSelectedView(<DescriptionCard key={id} id={id}></DescriptionCard>); break;
          default: setSelectedView(EmptySelected());
        } */

  }, [data])



  const onSelect1 = (e: any) => {
    //console.log(e);

    let formData = new FormData();
    formData.append("classsubject", e?.value);
    formData.append("classsubjectname", e?.label);
    submit(formData, { method: "POST" });

    //teacher.setSelectedClasSubjectSyllabus(e.target.value)
  }


  if (!classID || !subjectID) return (
    <Card>
      <Row gutter={[8, 8]}>
        <Col span={8}>
          <Form method="POST">
            <Select
              key={'csdfsdf'}
              onChange={onSelect1}
              name="classsubject" /* style={{ width: '100%' }}  */
              placeholder='Выберите класс/предмет'
              //value={classesSubjects.filter((item: any) => item.value === selectedClassSubject)}
              options={classesSubjects}
            />
          </Form>
        </Col>
      </Row>
    </Card>

  )

  /*   const onLoadData1 = async ({ key, children }: any) => {
      //var params: number | string | undefined | string[] = undefined;
      var ret = [];
      var tree: DataNode[] = [];
      var isLeaf: boolean = true;
  
      //console.log("load tree", key, children);
      const { _type, id }: any = key2number(key);
  
      switch (_type) {
        case 'module': ret = await fetchThemes(id); isLeaf = false; break;
        case 'theme': ret = await fetchRoutes(id); isLeaf = false; break;
        case 'route': ret = await fetchDescriptions(id); isLeaf = true; break;
      }
  
      for (const item of ret) {
        tree.push({
          isLeaf,
          title: (item.name) ? `${item.name} ` : (item.description) ? item.description : item.text,
          key: `${key}-${item.id}`,
        })
      }
      switch (_type) {
        case 'module': tree.push(AddButton({ type: 'theme', key, name: 'Добавить тему' })); break;
        case 'theme': tree.push(AddButton({ type: 'route', key, name: 'Добавить умение' })); break;
        case 'route': tree.push(AddButton({ type: 'description', key, name: 'Добавить задание' })); break;
      }
  
      setTreeData((origin) =>
        updateTreeData(origin, key, tree)
      );
  
    } */

  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    //console.log('Trigger Select', keys, info);
//    setSelectedKeys(keys);
//    setExpandedKeys(keys);
    navigate(`${target.id}/${String(keys[0]).replace(/-/gi, '/')}`);
    //    const { _type, id }: number | any = key2number(keys[0]);

    //        switch (_type) {
    //      case 'module': navigate(target.id + '/module/' + id);  break;//setSelected(<Navigate to={'module/'+id}/>); break; //{/* <ModuleCard key={id} id={id}></ModuleCard> */}
    //      case 'theme': navigate(target.id + '/theme/' + id); break;//setSelected(<ThemeCard key={id} id={id}></ThemeCard>); break;
    //          case 'route': setSelectedView(<RouteCard key={id} id={id}></RouteCard>); break;
    //          case 'description': setSelectedView(<DescriptionCard key={id} id={id}></DescriptionCard>); break;
    //          default: setSelectedView(EmptySelected());
    //        }
  };

  const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
    setExpandedKeys(keys);
    //console.log('Trigger Expand', keys, info);
  };

  const onDragEnter: TreeProps['onDragEnter'] = (info) => {
    //console.log(info);
    // expandedKeys, set it when controlled is needed
    // setExpandedKeys(info.expandedKeys)
  };

  const onDrop: TreeProps['onDrop'] = (info) => {
    console.log("position", info.dropPosition, info.dragNode.key);
    console.log(info);
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    if (String(dropKey).split('-').length > 1) return; // нельзя в третий уровень кинуть
    //if (!(String(dropKey).split('-').length === String(dragKey).split('-').length-1)) return;

    const loop = (
      data: TreeDataNode[],
      key: React.Key,
      callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void,
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback);
        }
      }
    };
    const data = [...treeData];

    // Find dragObject
    let dragObj: TreeDataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
      });
    } else {
      let ar: TreeDataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i!, 0, dragObj!);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i! + 1, 0, dragObj!);
      }
    }
    setTreeData(data);
  };


  return (<Card title={target?.subjectName + '  -  ' + target.className} >
    <Row gutter={[8, 8]}>
      <Col span={12}>
        <DirectoryTree
          draggable
          selectedKeys={selectedKeys}
          expandedKeys={expandedKeys}
          /* loadData={onLoadData1} */
          treeData={treeData}
          onSelect={onSelect}
          onExpand={onExpand}
          showIcon={false}
          expandAction={false}
          onDragEnter={onDragEnter}
          onDrop={onDrop}
        />
      </Col>
      <Col span={12}>
        <Outlet />
        {/* {selectedView} */}
      </Col>
      <Col span={10}>
        <SchoolBook classID={classID} subjectID={subjectID} />
      </Col>
    </Row>

    {/* <ModulesList classID={classID} subjectID={subjectID} /> */}
  </Card>
  )
}

