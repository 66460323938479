
import { Card, Select, Table } from "antd";
import { useEffect, useRef, useState, Suspense, useMemo } from "react";
import { useReactToPrint } from "react-to-print";
import { FaPrint } from "react-icons/fa"
import { useTable, CellProps, Column } from "react-table";
import { Await, defer, Outlet, useLoaderData, useSubmit } from "react-router-dom";
import { fetchSilabusV2 } from "../http/teacherApi";
import SilabusModuleStudentV2Table from "./SilabusModuleStudentV2Table";
import studentAPI from "../http/classStudentAPI";
import ModalAnswers, { actionModalAnswerV2, loaderModalAnswerV2 } from "./ModalAnswer";
import { fetchSubjectsTacts } from "../http/parentApi";
import { getClassByUserID, getSubjectsByUserID } from "../http/studentApi";
import serviceAPI from "../http/classServiceAPI";
//import dayjs from 'dayjs';

export const silabusStudentV2Router = [
    { index: true, element: <></>, /* action: newEvent */ },
    { path: ':id/desc', element: <ModalAnswers />, loader: loaderModalAnswerV2, action: actionModalAnswerV2 },
    { path: ':tactID/tact', element: <></> },
    { path: ':subjectID/subject', element: <></> },
    { path: ':tactID/:subjectID', element: <></> },
    { path: ':tactID/:subjectID/:id', element: <ModalAnswers />, loader: loaderModalAnswerV2, action: actionModalAnswerV2  },

]

export async function loaderSilabusStudentV2({ params }: any) {

    //console.log("loader silabus V2", params);
    const studentID = Number(params['studentID'] || 0);
    const tactID = Number(params['tactID'] || 0);
    const subjectID = Number(params['subjectID'] || 0);

    const raw_tacts = await serviceAPI.tacts();//fetchSubjectsTacts(studentID)
    const tacts = raw_tacts.map((item: any) => { return { label: item?.name, value: item?.id } })
    const selectedTact = raw_tacts.find((item: any) => item.id === tactID)

    const raw_subjects = await getSubjectsByUserID(studentID)
    const subjects = raw_subjects.map((item: any) => { return { label: item?.name, value: item?.id } })

    const classID = await getClassByUserID(studentID)

    //    const { /* classID,  subjectID,*/ teachingBegin, researchingEnd } = { /* classID: 2,  subjectID: 7, teachingBegin: '2024-09-16', researchingEnd: '2025-10-31'*/ };

    var raw_modules = [];

    if (selectedTact) {
        raw_modules = await studentAPI.silabusV2(classID, subjectID, selectedTact.teachingBegin, selectedTact.researchingEnd);

        //    console.log("subjects", raw_tacts, selectedTact);




    }

    console.log("silabusV2 loader ", params, selectedTact);

    return defer({ raw_modules, tacts, subjects, tactID, subjectID });
}


const styleModules = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleHeader = { background: '#bfbfbf', padding: '3px', border: 'solid 1px gray' };
const styleData = { padding: '3px', border: 'solid 1px gray' };


export async function action({ request, params }: any) {
    console.log("silabusStudentV2_action", request, params);

/*     let formData = await request.formData();
    console.log('=====dire stat act===============================');
    console.log(formData);
    console.log('====================================');
    return await updateSilabus(params.id, {
        add: formData.get('add') === 'true',
    });
 */}


const prepare_modules = (raw_modules: any) => {
    console.log("prepare_modules", raw_modules);

    var modules = [];
    for (const module of raw_modules) {
        const themes = module['themes']
        const data = [];
        for (const theme of themes) {
            var skills = <></>;
            if (theme?.skills.length) {
                skills = <ul style={{ margin: '0', padding: '0px 0px 0px 10px' }}>{theme.skills.map((skill: any) => { return (<li style={{ fontSize: '12px' }}>{(skill.name) ? skill.name : "!"}</li>) })}</ul>
            }
            if (theme?.descriptions && theme?.descriptions.length) {
                var rowSpan = theme.descriptions.length
                for (const desc of theme.descriptions) {
                    const { studentScore, teacherScore } = desc['responses'][0];
                    data.push({ theme: theme['name'] as string, skills, desc: desc.description, id: 'd-' + desc.id, rowSpan, style: { background: desc?.params?.color, border: 'solid 1px gray' }, score: teacherScore, self_score: studentScore })
                    rowSpan = 0;
                }
            } else {
                data.push({ theme: theme['name'] as string, skills, desc: '', id: "n-" + Math.random(), rowSpan: 1 })
            }
        }
        module['themes'] = data;
        modules.push(module)
    }
    return modules;
}


export const SilabusStudentV2 = () => {

    const { raw_modules, tacts, subjects, tactID, subjectID } = useLoaderData() as any;
    console.log("data", tactID, subjectID);

    const hovered = useState(0);

    const submit = useSubmit();
    const componentRef = useRef(null);

    const memo_modules = useMemo(() => prepare_modules(raw_modules) || [], [raw_modules])


    const onClick = (item: string) => {
        const [check, id] = item.split("-");
        if (check === 'd') {
            let formData = new FormData();
            formData.append("id", id);
            //formData.append("classname", e?.label);
            //        submit(formData, { method: "GET", action: `${id}` });
            submit(null, { method: "GET", action: `${tactID}/${subjectID}/${id}` });
        }
        //if (check === 'd') openModal(Number(id))
    }


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })


    const handleChangeTacts = async (value: any) => {
        console.log("click", value);

        let formData = new FormData();
        //formData.append("id", value);
        //formData.append("classname", e?.label);
        //        submit(formData, { method: "GET", action: `${id}` });
        if (subjectID) {
            submit(null, { method: "GET", action: `${value}/${subjectID}` });
        } else {
            submit(null, { method: "GET", action: `${value}/tact` });
        }
        //var data = [[], []];
        /* if (mode == 'student') {
            data = await fetchStar1Data(value);
        } else {
            if (parent.studentData.id) {
                data = await fetchStar1DataBSID(parent.studentData.id, value)
            }
    
        }
        setStar1(data); */

    };

    const handleChangeSubjects = async (value: any) => {
        if (tactID) {
            submit(null, { method: "GET", action: `${tactID}/${value}` });
        } else {
            submit(null, { method: "GET", action: `${value}/subject` });
        }
        //        var data = [[], []];
        /* if (mode == 'student') {
            data = await fetchStar1Data(value);
        } else {
            if (parent.studentData.id) {
                data = await fetchStar1DataBSID(parent.studentData.id, value)
            }
    
        }
        setStar1(data); */

    };


    return (
        <>
            <Card /* title={hovered[0]}  */ extra={<button onClick={handlePrint}><FaPrint /></button>}>
                <div style={{ width: '50%', padding: '12px' }}>
                    <Select
                        showSearch={false}
                        //mode='multiple'
                        placeholder='Выберите предмет'
                        options={subjects}
                        style={{ width: '100%' }}
                        onChange={handleChangeSubjects}
                    />
                </div>
                <div style={{ width: '50%', padding: '12px' }}>
                    <Select
                        showSearch={false}
                        //mode='multiple'
                        placeholder='Выберите такт'
                        options={tacts}
                        style={{ width: '100%' }}
                        onChange={handleChangeTacts}
                    />
                </div>
                <Outlet />
                <div style={{ width: '100%' }} ref={componentRef}>
                    {/*                     <Suspense fallback={<>Load</>}>
                        <Await resolve={memo_modules}> */}
                    {memo_modules &&
                        <>
                            {memo_modules.map((module: any) => (
                                //SilabusModuleStudentV2Table(module, hovered, onClick))
                                <SilabusModuleStudentV2Table key={module.id} module={module} hovered={hovered} onClick={onClick} />
                            ))}
                        </>
                    }
                    {/* </Await>
                    </Suspense> */}
                </div>
            </Card>
        </>
    )

}

export default SilabusStudentV2;
