
import { Card, Col, DatePicker, Form, Row, Select, Table } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { FaPrint } from "react-icons/fa"
//import { getSilabus, updateSilabus, setSilabus } from '../../api/reports/silabus';
//import SilabusService from "../../services/SilabusService";
import { fetchClasses, fetchSilabus, fetchSubjects, fetchWorkTypes } from "../http/adminApi";
import { dateFormat, dateNumberFormat } from "./utilites";
import { Context } from "..";
import dayjs from 'dayjs';
//import { SelectSubjects } from "../SelectSubjects/SelectSubjects";
/* 
export async function loader({ params }: any) {
    const Modules = await SilabusService.fetchSilabus();//  as SilabusData;
    if (!Modules) {
        throw new Response('', {
            status: 404,
            statusText: 'Не найден',
        });
    }
    console.log('======loader====dir stat==========================');
    console.log(Modules.data);
    console.log('====================================');
    return { Modules: Modules.data };
}

 */export async function action({ request, params }: any) {
/*     let formData = await request.formData();
    console.log('=====dire stat act===============================');
    console.log(formData);
    console.log('====================================');
    return await updateSilabus(params.id, {
        add: formData.get('add') === 'true',
    });
 */}

export interface ModulesItem {
    key: React.Key;
    name: string;
}

export type ModulesData = {
    Modules: ModulesItem[];
};




export const Silabus = () => {
    const { admin } = useContext(Context) as any;

    const { RangePicker } = DatePicker;
    const [formSilabusFilter] = Form.useForm();

    //    const { Modules } = useLoaderData() as ModulesData;

    const [classes, setClasses] = useState();
    const [subjects, setSubjects] = useState();
    const [workTypes, setWorkTypes] = useState();
    const [columns, setColumns] = useState([{}]);
    const [data, setData] = useState([{}]);
    //    const [query, setQuery] = useState();

    if (!admin.selectedInterval) {
        admin.setSelectedInterval([dayjs(), dayjs()]);
        formSilabusFilter.setFieldsValue({ Interval: [dayjs(), dayjs()] });
    } else {
        formSilabusFilter.setFieldsValue({ Interval: admin.selectedInterval });
    }

    const loadClasses = async () => {
        const classes = await fetchClasses();
        setClasses(classes.map((item: any) => { return { label: item.name, value: item.id } }));
        formSilabusFilter.setFieldsValue({ Class: admin.selectedClass });
    }

    const loadSubjects = async () => {
        const subjects = await fetchSubjects();
        setSubjects(subjects.map((item: any) => { return { label: item.name, value: item.id } }));
        formSilabusFilter.setFieldsValue({ Subject: admin.selectedSubject });
    }

    const loadWorkTypes = async () => {
        const ret = await fetchWorkTypes();
        setWorkTypes(ret.map((item: any) => { return { label: item.name, value: item.id } }));
        formSilabusFilter.setFieldsValue({ workType: admin.selectedWorkType });
    }

    const loadSilabus = async (_class: number, _subject: number, _workType: number, _begin: string, _end: string) => {
        const ret = await fetchSilabus('class=' + _class + '&subject=' + _subject + '&worktype=' + _workType + '&begin=' + _begin + '&end=' + _end);
        console.log('====================================');
        console.log(ret);
        console.log('====================================');

        setColumns(ret?.columns);
        setData(ret?.data);
    }

    useEffect(() => {
        loadClasses();
        loadSubjects();
        loadWorkTypes();
    }, [])



    const onFormSilabusChange = (event: any) => {
        var _begin, _end;
        const query = formSilabusFilter.getFieldsValue();
        console.log('====================================');
        console.log(query);
        console.log('====================================');
        if (query['Class'] && query['Subject'] && query['workType'] && query['Interval']) {
            const interval = query['Interval'];
            console.log('====================================');
            console.log(interval.length);
            console.log('====================================');


            admin.setSelectedWorkType(query['workType']);
            admin.setSelectedClass(query['Class']);
            admin.setSelectedSubject(query['Subject']);
            admin.setSelectedInterval(query['Interval']);

            if (interval.length > 2) {
                _begin = query['Interval'][0].format(dateNumberFormat);
                _end = query['Interval'][1].format(dateNumberFormat);
                loadSilabus(query['Class'], query['Subject'], query['workType'], _begin, _end);
            }

            //            setQuery(query);
            console.log('==onFormSilabusChange==================================');
            console.log(query);
            console.log('====================================');
        }
    }

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    /*
        const columns = [
                 {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    className: '',
                },
                {
                    title: 'Age',
                    dataIndex: 'age',
                    key: 'age',
                },
                {
                    title: 'Address',
                    dataIndex: 'address',
                    key: 'address',
                }, 
        ];
        */

    return (
        <Card extra={<button onClick={handlePrint}><FaPrint /></button>}>
            <Form form={formSilabusFilter} component={false}
                labelCol={{ span: 5 }}

                wrapperCol={{ span: 12 }}
                //layout="inline"
                initialValues={{ size: 'small', /* Class: admin.selectedClass, Subject: admin.selectedSubject, workType: admin.selectedWorkType, Interval: admin.selectedInterval ? admin.selectedInterval : [] */ }}
                onValuesChange={onFormSilabusChange}
                //                onValuesChange={props.onChange}
                size="small"
                style={{ maxWidth: 'none' }}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item label="Класс" name="Class" >
                            <Select options={classes} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>

                        <Form.Item label="Предмет" name="Subject" >
                            <Select options={subjects} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label="Период" name="Interval" >
                            <RangePicker />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Контроль" name="workType"  /* wrapperCol={{ span: 15 }} */>
                            <Select options={workTypes} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table ref={componentRef} columns={columns} bordered={true} size="small" dataSource={data} />
        </Card>
    )
}

export default Silabus;
