import ClassAPI from "./classAPI";

class ClassStudentAPI extends ClassAPI {
    constructor () {
        super('student')
    }

    async performance () {
        const ret = await this.get({url: 'performance'})
        return (ret) ? ret : [];
    }

    async silabusV2 (classID:number, subjectID:number, begin:string, end:string) {
        const ret = await this.get({url: `silabusV2/${classID}/${subjectID}/${begin}/${end}`})
        return (ret) ? [...ret] : [];
    }
    async getDescription (id: string) {
        const ret = await this.get({url: `descriptionV2/${id}`})
        return (ret) ? {...ret} : {};
//        return ret
    }
}

const  studentAPI  = new ClassStudentAPI()

export default studentAPI;

